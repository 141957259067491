<template>
  <div
    class="d-flex flex-column flex-grow-1 flex-fill mt-3 animate__animated animate__fadeIn"
  >
    <div
      v-if="lubeSaleSegregation.length > 0"
      class="d-flex flex-column flex-grow-1 flex-fill grey lighten-4 px-3 py-2"
    >
      <span class="ft font-weight-medium font-size-md text-uppercase"
        >Add All Credit Sales</span
      >

      <v-slide-y-transition group>
        <div
          v-for="(sale, index) in lubeSaleSegregation"
          :key="index"
          class="d-flex flex-column flex-grow-1 my-1 white py-3 box-shadow-light"
        >
          <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1">
            <div class="d-flex flex-row flex-grow-1">
              <validationProvider tag="div" class="d-flex flex-row flex-grow-1">
                <v-autocomplete
                  label="Choose a customer"
                  :items="customers"
                  v-model="sale.customerName"
                  return-object
                  item-text="name"
                  item-value="id"
                  class="mt-n2"
                >
                  <template #item="{item}">
                    <span class=" ft font-weight-bold font-size-sm">
                      {{ item.name }}
                      <v-chip label small color="primary">{{
                        item.businessRegistrationNumber
                          ? "Business"
                          : "Customer"
                      }}</v-chip>
                    </span>
                  </template>
                </v-autocomplete>
              </validationProvider>
            </div>

            <div class="d-flex flex-row flex-grow-1">
              <ValidationProvider
                tag="div"
                class="d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  prepend-icon="credit_card"
                  label="Credit Sales Amount"
                  v-model="sale.amount"
                  :prefix="
                    currency && currency.length > 0 ? currency[0].symbol : 'GHS'
                  "
                  :error-messages="errors[0]"
                  class="ft font-weight-medium font-size-md pa-1"
                  dense
                />
              </ValidationProvider>
            </div>
            <div class="d-flex flex-row flex-grow-1">
              <ValidationProvider tag="div" class="d-flex flex-row flex-grow-1">
                <v-text-field
                  prepend-icon="comment"
                  label="Comment"
                  v-model="sale.comment"
                  class="ft font-weight-medium font-size-md pa-1"
                  dense
                />
              </ValidationProvider>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  small
                  color="red"
                  @click.stop="removeRevenueRow(index)"
                >
                  <i class="material-icons-outlined">delete</i>
                </v-btn>
              </template>
              <span class=" ft font-weight-medium font-size-sm"
                >Remove Item</span
              >
            </v-tooltip>
          </div>
        </div>
      </v-slide-y-transition>
      <v-alert
        v-if="lubeSalesAmount < sum(lubeSaleSegregation, 'amount')"
        dense
        type="error"
        class="mx-2 mt-2"
      >
        <span class="font font-weight-medium font-size-sm"
          >Amount cannot be greater than the total sales</span
        >
      </v-alert>

      <div
        class="d-flex flex-row flex-grow-1 "
        style="justify-content: flex-end;"
      >
        <v-btn
          color="primary"
          :disabled="
            lubeSalesAmount < sum(lubeSaleSegregation, 'amount') ||
              hasSalesBeenRecorded
          "
          style="font-size: 12px;"
          small
          @click.stop="addRevenueRow"
          class="ft font-weight-medium mr-2 mt-2"
        >
          Add Credit Sale
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 justify-center grey lighten-4 pa-5"
      style="align-items:center;"
    >
      <img src="@/assets/online-payment.svg" style="width: 80px;" />
      <span class="ft font-weight-medium"
        >Add all sales type for lubes here <kbd class="mr-2">{{ date }}</kbd
        >.</span
      >
      <span class="ft font-size-sm font-weight-medium">
        Sales types may include credit sales for lubes
      </span>
      <v-btn
        :disabled="
          lubeSalesAmount < sum(lubeSaleSegregation, 'amount') ||
            hasSalesBeenRecorded
        "
        @click.stop="addRevenueRow"
        small
        color="primary"
        class="mt-3"
      >
        <span
          class="ft font-weight-medium font-size-sm"
          style="font-size: 12px !important;"
          >Add Credit Sale</span
        >
      </v-btn>
    </div>
  </div>
</template>
<script>
import { extend, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: "Input field is required"
});
export default {
  name: "RecordLubeSegregations",
  components: {
    ValidationProvider
  },
  props: {
    loading: Boolean,
    subLoading: Boolean,
    hasSalesBeenRecorded: Boolean,
    currency: {
      type: Array,
      default: () => []
    },
    date: {
      type: String,
      default: ""
    },
    lubeSalesAmount: {
      type: Number,
      default: 0
    },
    branchId: {
      type: String,
      default: null
    },
    lubeSaleSegregation: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    editing: null,
    model: null,
    query: "",
    search: ""
  }),
  computed: {
    ...mapGetters({
      customers: "customers/getCustomerSearchResults"
    })
  },

  methods: {
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    addRevenueRow() {
      this.$emit("addRevenueRow", {
        customerName: "",
        amount: 0,
        comment: ""
      });
    },
    removeRevenueRow(index) {
      this.lubeSaleSegregation.splice(index, 1);
    }
  }
};
</script>
