var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill mt-3 animate__animated animate__fadeIn"},[(_vm.lubeSaleSegregation.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill grey lighten-4 px-3 py-2"},[_c('span',{staticClass:"ft font-weight-medium font-size-md text-uppercase"},[_vm._v("Add All Credit Sales")]),_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.lubeSaleSegregation),function(sale,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1 my-1 white py-3 box-shadow-light"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('validationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"}},[_c('v-autocomplete',{staticClass:"mt-n2",attrs:{"label":"Choose a customer","items":_vm.customers,"return-object":"","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:" ft font-weight-bold font-size-sm"},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(item.businessRegistrationNumber ? "Business" : "Customer"))])],1)]}}],null,true),model:{value:(sale.customerName),callback:function ($$v) {_vm.$set(sale, "customerName", $$v)},expression:"sale.customerName"}})],1)],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md pa-1",attrs:{"prepend-icon":"credit_card","label":"Credit Sales Amount","prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"error-messages":errors[0],"dense":""},model:{value:(sale.amount),callback:function ($$v) {_vm.$set(sale, "amount", $$v)},expression:"sale.amount"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"}},[_c('v-text-field',{staticClass:"ft font-weight-medium font-size-md pa-1",attrs:{"prepend-icon":"comment","label":"Comment","dense":""},model:{value:(sale.comment),callback:function ($$v) {_vm.$set(sale, "comment", $$v)},expression:"sale.comment"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeRevenueRow(index)}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium font-size-sm"},[_vm._v("Remove Item")])])],1)])}),0),(_vm.lubeSalesAmount < _vm.sum(_vm.lubeSaleSegregation, 'amount'))?_c('v-alert',{staticClass:"mx-2 mt-2",attrs:{"dense":"","type":"error"}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Amount cannot be greater than the total sales")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-grow-1 ",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"ft font-weight-medium mr-2 mt-2",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","disabled":_vm.lubeSalesAmount < _vm.sum(_vm.lubeSaleSegregation, 'amount') ||
            _vm.hasSalesBeenRecorded,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.addRevenueRow($event)}}},[_vm._v(" Add Credit Sale ")])],1)],1):_c('div',{staticClass:"d-flex flex-column flex-grow-1 justify-center grey lighten-4 pa-5",staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/online-payment.svg")}}),_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Add all sales type for lubes here "),_c('kbd',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.date))]),_vm._v(".")]),_c('span',{staticClass:"ft font-size-sm font-weight-medium"},[_vm._v(" Sales types may include credit sales for lubes ")]),_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.lubeSalesAmount < _vm.sum(_vm.lubeSaleSegregation, 'amount') ||
          _vm.hasSalesBeenRecorded,"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addRevenueRow($event)}}},[_c('span',{staticClass:"ft font-weight-medium font-size-sm",staticStyle:{"font-size":"12px !important"}},[_vm._v("Add Credit Sale")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }